import React from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {useFormikContext} from "formik";

import Label from "components/input/new/Label";
import InputTextField from "components/input/new/InputTextField";

GenericOperatorInformationField.propTypes = {
    name: PropTypes.string.isRequired
};

function GenericOperatorInformationField({name}) {
    const {values, touched, errors} = useFormikContext();

    return (
        <>
            <Label required htmlFor={`${name}`}>
                <FormattedMessage id={`compliance.dac7.form.field.${name}`} />
            </Label>
            <InputTextField
                disabled
                name={name}
                value={values[name] ?? ''}
                fullWidth
                error={touched[name] && Boolean(errors[name])}
                helperText={touched[name] && errors[name]}
            />
        </>
    );
}

export default GenericOperatorInformationField;
