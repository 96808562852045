import React, {Component} from "react";

import {
    ConfirmationStep,
    PriceStep,
    ProductStep,
    StockStep,
    OptionsStep
} from "views/stockeasy/product/create/components/steps";
import BackBrowserModal from "../../../views/stockeasy/product/create/components/modal/backBrowserModal";
import BarcodeStep from "views/stockeasy/product/create/components/steps/barcodeStep";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import StockEasyStepper from "./StockEasyStepper";
import Congrats from "../../../views/stockeasy/product/create/components/steps/congratsStep";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl";
import CustomModal from "../../../views/stockeasy/product/components/customModal";
import {ReactComponent as NoPictureIcon} from "../../../assets/img/noPictureIcon.svg";
import localStorageSE from "../../../views/stockeasy/product/create/components/localStorageManager/localStorageSE"

class StockEasyStepManager extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 0,
            productWithoutBarcode: null,
            pictureModal: false,
        }
    }

    componentDidMount() {
        if (localStorageSE.getCurrentStep() && localStorageSE.getProductData()) {
            this.setState({step: localStorageSE.getCurrentStep()});
        }
    }

    renderStepper(stepIndex) {
        if (stepIndex < 6) {
            return <StockEasyStepper step={stepIndex}/>
        }
    }

    setProductWithoutBarcode(productWithoutBarcode) {
        this.setState({productWithoutBarcode: productWithoutBarcode});
    }

    removeDraft = () => {
        localStorageSE.removeData();
        this.setState({'step': 0});
    }

    changeStep(step, force = false) {
        localStorageSE.updateCurrentStep(step);
        let currentUrlParams = new URLSearchParams(this.props.location.search)
        currentUrlParams.set('step', step);

        // if the user want to go to step 2 with a product without any picture
        if (this.state.step === 1 && step === 2 && !force) {
            let product = false !== localStorageSE.getProductData() ? localStorageSE.getProductData() : this.state.productWithoutBarcode;
            if (product.pictures.length === 0) {
                this.setState({pictureModal: true});

                return;
            }
        }

        this.setState({
            pictureModal: false,
            step: step,
        });
    }

    renderStep(stepIndex) {
        switch(stepIndex) {
            case 0:
                return <BarcodeStep
                    goToNextStep={() => this.changeStep(1)}
                    setProductWithoutBarcode={(product) => this.setProductWithoutBarcode(product)}
                    retailerId={this.props.retailerId}
                    history={this.props.history}
                />;
            case 1:
                return <ProductStep
                    productWithoutBarcode={this.state.productWithoutBarcode}
                    goToNextStep={() => this.changeStep(2)}
                    removeDraft={() => this.removeDraft()}
                    history={this.props.history}
                    retailer={this.props.retailer}
                    corners={this.props.corners}
                    attributes={this.props.attributes}
                />;
            case 2:
                return <OptionsStep
                    history={this.props.history}
                    goToPreviousStep={() => this.changeStep(1)}
                    goToNextStep={() => this.changeStep(3)}
                    removeDraft={() => this.removeDraft()}
                    options={this.props.options}
                />;
            case 3:
                return <PriceStep
                    goToPreviousStep={() => this.changeStep(2)}
                    goToNextStep={() => this.changeStep(4)}
                    retailerId={this.props.retailerId}
                    removeDraft={() => this.removeDraft()}
                    options={this.props.options}
                />;
            case 4:
                return <StockStep
                    goToPreviousStep={() => this.changeStep(3)}
                    goToNextStep={() => this.changeStep(5)}
                    removeDraft={() => this.removeDraft()}
                    options={this.props.options}
                />;
            case 5:
                return <ConfirmationStep
                    goToPreviousStep={() => this.changeStep(4)}
                    goToNextStep={() => this.changeStep(6)}
                    retailerId={this.props.retailerId}
                    removeDraft={() => this.removeDraft()}
                    corners={this.props.corners}
                    attributes={this.props.attributes}
                />;
            case 6:
                return <Congrats
                    retailerId={this.props.retailerId}
                    removeDraft={() => this.removeDraft()}
                />
            default:
                return <BarcodeStep
                    goToNextStep={() => this.changeStep(1)}
                    setProductWithoutBarcode={(product) => this.setProductWithoutBarcode(product)}
                    retailerId={this.props.retailerId}
                    history={this.props.history}
                    removeDraft={() => this.removeDraft()}
                />;
        }
    }

    render() {
        const {classes} = this.props;
        const {step, pictureModal} = this.state;

        return (
            <GridContainer className={classes.container} justifyContent="center">

                <BackBrowserModal step={step}/>

                <CustomModal
                    open={pictureModal}
                    title={this.props.intl.formatMessage({id:"stockeasy.step.noPicture.modal.title"})}
                    contentText={this.props.intl.formatMessage({id:"stockeasy.step.noPicture.modal.content"})}
                    cancelButtonText={this.props.intl.formatMessage({id:"stockeasy.step.noPicture.modal.continue"})}
                    cancelButtonAction={() => {this.changeStep(2, true)}}
                    validateButtonText={this.props.intl.formatMessage({id:"stockeasy.step.noPicture.modal.stay"})}
                    validateButtonAction={() => {this.setState({pictureModal: false})}}
                    noticeText={this.props.intl.formatMessage({id:"stockeasy.step.noPicture.modal.notice"})}
                    icon={<NoPictureIcon/>}
                />

                {/*STEPPER*/}
                {this.renderStepper(step)}

                {/*STEP CONTENT*/}
                <GridItem xs={12} sm={10} md={8} lg={7} className={classes.gridItemStepContainer}>
                    {this.renderStep(step)}
                </GridItem>
            </GridContainer>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        dictionaries: state.dictionaries,
        productRequestData: state.stockEasy.request.retrieved,
    };
};

export default connect(
    mapStateToProps,
)(injectIntl(StockEasyStepManager));
