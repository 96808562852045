import {fetch, normalize} from "utils/dataAccess";

export function updatePosition(id, position) {
    return new Promise((resolve, reject) => {
        fetch(`/attributes/${id}/update_position`, {
            method: "POST",
            body: JSON.stringify({"position": position}),
        }).then(response => response.json()).then((retrieved) => {
            resolve(normalize(retrieved));
        }).catch(error => reject(error));
    });
}
