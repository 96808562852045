import React from 'react';
import {ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {Link} from "react-router-dom";
import GavelIcon from '@mui/icons-material/Gavel';
import {FormattedMessage} from "react-intl";
import {Authorizations, hasAuthorization} from "../../../utils/authorizations";

function DrawerCompliance({classes, authorizations}) {
    const isSelected = window.location.pathname.indexOf('/compliance') === 0;

    if (!hasAuthorization(authorizations, Authorizations.MARKETPLACE_COMPLIANCE)) {
        return null;
    }

    return (
        <div>
            <ListItemButton
                button
                className={classes.listItem}
                classes={{selected: classes.selected}}
                key={"compliance"}
                selected={isSelected}
                component={Link}
                to={'/compliance'}
            >
                <ListItemIcon>
                    <GavelIcon color={isSelected ? "primary" : "secondary"} />
                </ListItemIcon>
                <ListItemText
                    primaryTypographyProps={{color: isSelected ? "primary" : "secondary"}}
                    primary={<FormattedMessage id={"sidebar.item.compliance"}/>}
                />
            </ListItemButton>
        </div>
    );
}

export default DrawerCompliance;
