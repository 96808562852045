import React from "react";
import {connect} from "react-redux";
import {Table, TableBody, TableCell, TableHead, TableRow, } from "@mui/material";
import {withStyles} from "@mui/styles";
import showStyle from "../../../assets/jss/views/picking/order/showStyle";
import {FormattedDate, FormattedMessage, injectIntl} from "react-intl";
import logo from "assets/img/logo.png";
import FormattedNumberWithCurrency from "../../../components/formattedNumberWithCurrencies/formattedNumberWithCurrency";

class Justification extends React.Component {
    render() {
        const lineItems = this.props.retrieved["hydra:member"];
        let total = 0;
        const order = lineItems[0].order;

        return (
            <div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={this.props.classes.primaryTableHeaderCellLogo} colSpan={4}>
                                <img className={this.props.classes.logo} src={logo} alt={""}/>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={4}>
                                <h3>
                                    <FormattedMessage id={"picking.order.show.title"}/>
                                    <b>#{order.externalId}</b><br/>
                                    <b>{order.customerBillingLastname} {order.customerBillingFirstname}</b><br/>
                                    <FormattedDate
                                        value={new Date(lineItems[0].createdAt)} year={"numeric"}
                                        month={"numeric"} day={"numeric"} hour={"numeric"}
                                        minute={"numeric"}
                                    />
                                    <br/>
                                    {null !== order.customerShippingCompany && order.customerShippingCompany + ", "}
                                    {order.customerShippingAddress + ", "}
                                    {null !== order.customerShippingAddressMore && order.customerShippingAddressMore + ", "}
                                    {order.customerShippingPostalCode + " "}
                                    {order.customerShippingCity}
                                    <br />
                                    {order.customerShippingCountry}
                                    <br/>
                                    {order.customerShippingPhone}
                                    <br/>
                                    {order.customerBillingEmail}
                                    <br/>
                                </h3>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={3}><FormattedMessage id={"picking.order.show.table.header.name"}/></TableCell>
                            <TableCell colSpan={1} className={this.props.classes.tableCellRight}><FormattedMessage id={"picking.order.show.table.header.price"}/></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(lineItems).length > 0 &&
                            Object.keys(lineItems)
                                .filter(key => "canceled" !== lineItems[key].status && "unavailable" !== lineItems[key].status)
                                .map((key) => {
                                    total += lineItems[key].retailerPrice;

                                    return (
                                        <TableRow key={key}>
                                            <TableCell colSpan={3}>{lineItems[key].name} <br/> {lineItems[key]?.variationSku}<br/> <FormattedMessage id={`picking.order.show.label.shippingMethod`} /> : <FormattedMessage id={`picking.order.shippingMethod.${lineItems[key].shippingMethod}`} /></TableCell>
                                            <TableCell colSpan={1} className={this.props.classes.tableCellRight}><FormattedNumberWithCurrency value={lineItems[key].retailerPrice} currency={this.props.currentOrganization?.currency} /></TableCell>
                                        </TableRow>
                                    );
                                })
                        }
                        <TableRow>
                            <TableCell colSpan={4} className={this.props.classes.tableCellRight}>
                                <h3>
                                    <FormattedMessage id={"picking.order.show.label.total"}/>
                                    <b><FormattedNumberWithCurrency value={total} currency={this.props.currentOrganization?.currency} /></b><br/>
                                </h3> <br/>
                                <h3>
                                    <FormattedMessage id={"picking.order.show.label.total_items"}/>
                                    <b>{ lineItems.length }</b><br/>
                                </h3>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        retrieved: state.lineItem.list.retrieved,
        currentOrganization: state.currentOrganization.retrieved ?? null,
    };

};

export default connect(
    mapStateToProps,
    null
)(withStyles(showStyle)(injectIntl(Justification)));
