import React from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import {useFormikContext} from "formik";

import SelectTitle from "components/radioGroup/SelectTitle";
import FormControlLabel from "components/radioGroup/FormControlLabel";
import RadioGroup from "components/radioGroup/RadioGroup";

function DestinationCountryField({countryList, ...props}) {
    const {values, handleChange} = useFormikContext();

    return (
        <>
            <SelectTitle required={true} titleTranslationKey='compliance.dac7.form.field.destinationCountry' />
            <RadioGroup
                placeholder={<FormattedMessage id={'compliance.dac7.form.field.destinationCountry.placeholder'} />}
                alignListWithButton
                selectedValueLabel={values.destinationCountry ? props.intl.formatMessage({id :`compliance.dac7.form.country.${values.destinationCountry}`}) : ''}
                name={'destinationCountry'}
                value={values.destinationCountry}
                onChange={handleChange}
            >
                {countryList.map((countryCode, index) => (
                    <FormControlLabel
                        key={index}
                        value={countryCode}
                        label={props.intl.formatMessage({id :`compliance.dac7.form.country.${countryCode}`})}
                    />
                ))}
            </RadioGroup>
        </>
    );
}

export default injectIntl(DestinationCountryField);
