import React, {useContext, useEffect, useState} from 'react';
import {Draggable} from "react-beautiful-dnd";
import {FixedSizeList} from "react-window";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";

import DragAndDropContext from "components/dragAndDrop/DragAndDropContext";

import CreateBelow from "../../../configuration/components/CreateBelow";
import Form from "./components/Form";
import Header from "./components/Header";
import ItemLine from "./components/ItemLine";

import {ListContext} from "components/List/context/ListContext";

import list from "api/attribute/list";
import listCategories from "api/category/list";
import {updatePosition} from "api/attribute/updatePosition";

function Content(props) {
    const [lastPosition, setLastPosition] = useState(0);

    const [isElementBeingDragged, setIsElementBeingDragged] = useState(false);

    const {
        loading,
        setLoading,
        listItems,
        setListItems,
        selectedItem,
        setSelectedItem,
        setNotFilteredListItems,
        createBelow,
        showCreateBelow,
        hideCreateBelow,
        openCreateDialogViaCreateBelow,
        isCreateDialogOpen,
        closeCreateDialog,
        showErrorNotification,
        moveListItem,
    } = useContext(ListContext)

    const [listCategoriesItem, setListCategoriesItem] = useState([]);

    // componentDidMount
    useEffect(() => {
        getAttributes();
    }, []);

    function getAttributes() {
        closeCreateDialog();

        setSelectedItem(null);

        setLoading(true);

        listCategories().then((response) => {
            const transformedCategories = transformCategories(response);
            setListCategoriesItem(transformedCategories);
        })

        list().then((response) => {
            setListItems(response);
            setNotFilteredListItems(response);

            if (response.length > 0) {
                setLastPosition(response[response.length - 1].position)
            }
        }).catch(() => {
            showErrorNotification();
        }).finally(() => {
            setLoading(false);
        })
    }

    function transformCategories(categories) {
        const categoryMap = categories.reduce((acc, category) => {
            acc[category.id] = category;
            return acc;
        }, {});

        const transformedCategories = categories.map(category => {
            let name = category.translations.fr_FR.name;
            let parent = category.parent;

            while (parent) {
                name = `${parent.translations.fr_FR.name} > ${name}`;
                parent = categoryMap[parent.id]?.parent;
            }

            return {
                ...category,
                displayName: name
            };
        });

        return transformedCategories.sort((a, b) => a.displayName.localeCompare(b.displayName));
    }

    function moveAttribute(result) {
        if (!result.destination) {
            return;
        }

        setIsElementBeingDragged(false);

        // update on front
        moveListItem(result)

        // update on back
        const destinationAttributePosition = listItems[result.destination.index]?.position;

        if (!destinationAttributePosition) {
            return;
        }

        setLoading(true);

        updatePosition(result.draggableId, destinationAttributePosition)
            .then(() => getAttributes())
            .catch(() => showErrorNotification())
            .finally(() => setLoading(false))
        ;
    }

    return (
        <Grid container>
            <Header />
            <Grid item container>
                <DragAndDropContext
                    onDragEnd={(result) => moveAttribute(result)}
                    onDragStart={() => setIsElementBeingDragged(true)}
                    droppableId={"attributeList"}
                    items={listItems}
                    renderClone={(data) => <ItemLine attribute={data} />}
                >
                    {droppableProvided => (
                        <FixedSizeList
                            height={window.innerHeight * 0.79}
                            itemCount={listItems.length}
                            itemSize={58}
                            width={'100%'}
                            itemData={listItems}
                            innerRef={droppableProvided.innerRef}
                        >
                            {({data, index, style}) => {
                                const attribute = data[index];

                                if (!attribute) {
                                    return null;
                                }

                                return (
                                    <Grid
                                        item
                                        container
                                        key={attribute.id}
                                        onMouseEnter={(event) => {
                                            !isElementBeingDragged && showCreateBelow(event, attribute.id)
                                        }}
                                        onMouseLeave={() => !isElementBeingDragged && hideCreateBelow()}
                                        sx={{...style, padding: '12px 0'}}
                                    >
                                        <Draggable
                                            draggableId={attribute.id}
                                            index={index}
                                            isDragDisabled={loading}
                                        >
                                            {(provided, snapshot) => (
                                                <ItemLine
                                                    attribute={attribute}
                                                    isDragging={snapshot.isDragging}
                                                    innerRef={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                />
                                            )}
                                        </Draggable>
                                        <CreateBelow
                                            isVisible={attribute.id === createBelow.listItemId}
                                            onClick={() => openCreateDialogViaCreateBelow(attribute.position)}
                                            style={{ bottom: '-28px' }}
                                        />
                                    </Grid>
                                )
                            }}
                        </FixedSizeList>
                    )}
                </DragAndDropContext>
            </Grid>
            <Dialog
                open={isCreateDialogOpen}
                onClose={() => closeCreateDialog()}
                maxWidth={'sm'}
                scroll={'body'}
            >
                <DialogContent>
                    <Form
                        refreshList={getAttributes}
                        selectedAttribute={selectedItem}
                        createBelow={createBelow}
                        lastPosition={lastPosition}
                        listCategoriesItem={listCategoriesItem}
                    />
                </DialogContent>
            </Dialog>
        </Grid>
    );
}

export default Content;
