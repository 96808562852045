import React, {useContext, useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage, injectIntl} from "react-intl";
import {Formik, Form as FormikForm} from "formik";
import * as yup from "yup";
import {lazy} from "yup";
import mapValues from "lodash/mapValues";
import { useHistory } from 'react-router-dom';

import Grid from "@mui/material/Grid";

import PageContent from "components/page/PageContent";
import ActionsContainer from "components/containers/ActionsContainer";
import CancelButtonStyled from "components/button/CancelButtonStyled";
import SaveButton from "components/button/SaveButton";

import MainInformation from "./components/MainInformation";
import ProductReferences from "./components/ProductReferences";
import Attributes from "./components/Attributes";
import LockProductButton from "./components/LockProductButton";

import {ProductShowContext} from "../../context/ProductShowContext";
import {ProductFormContext} from "./context/ProductFormContext";

import {update} from "api/product/update";
import createLock from "api/product/lock/create";
import updateLock from "api/product/lock/update";

import {objectsHaveDifferentValues} from "utils/objectsHaveDifferentValues";
import getTranslationInitialValues from "utils/getTranslationInitialValues";

import {StoreContext} from "../../../../../../contexts/storeContext";
import {green500} from "../../../../../../assets/jss/main";
import {getTranslation} from "../../../../../../domain/helpers/translations";


ProductForm.propTypes = {
    product: PropTypes.shape({
        translations: PropTypes.object,
        sku: PropTypes.string,
        externalId: PropTypes.string,
        gender: PropTypes.object,
        brand: PropTypes.object,
        taxRate: PropTypes.object,
        categories: PropTypes.array,
        corners: PropTypes.array,
    })
};

function ProductForm({product, ...props}) {
    const [selectedLocale, setSelectedLocale] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const store = useContext(StoreContext);
    const {refreshProduct, currentRetailer, attributeList} = useContext(ProductShowContext);

    const currentOrganization = store.getState()?.currentOrganization?.retrieved;
    const hasRequiredAttributes = attributeList && attributeList.some(attribute => attribute.required);

    const history = useHistory();

    useEffect(() => {
        if (!currentOrganization) return;
        setSelectedLocale(currentOrganization.locales[0]);
    }, [currentOrganization])

    let attributesValidationSchema = {}

    Object.entries(attributeList).forEach(([key, values]) => {
        let yupSchema = yup;

        if (values.type === 'string' || values.type === 'choice') {
            yupSchema = yupSchema.string();
        }

        if (values.type === 'url') {
            yupSchema = yupSchema.string().url();
        }

        if (values.type === 'float') {
            yupSchema = yupSchema
                .number()
                .typeError(
                    props.intl.formatMessage(
                        {id: 'product.show.product.form.field.attribute.error.type'},
                        {fieldName: getTranslation(values).name}
                    )
                )
        }

        if (values.type === 'multiple_choice') {
            yupSchema = yupSchema
                .array();

            if (values.required) {
                yupSchema = yupSchema.min(1, props.intl.formatMessage({id: 'product.show.product.form.field.attribute.error.min'}));
            }
        }

        const isRequired = values.required

        if (isRequired) {
            yupSchema = yupSchema.required(
                props.intl.formatMessage(
                {id: 'product.show.product.form.field.attribute.error.required'},
                {fieldName: getTranslation(values).name}
                )
            )
        } else {
            yupSchema = yupSchema.nullable();
        }

        attributesValidationSchema[values.id] = yupSchema;
    });

    const validationSchema = yup.object({
        translations: lazy(obj => yup.object(
            mapValues(obj, () => yup.object({
                name: yup
                    .string()
                    .required(props.intl.formatMessage({id: 'product.show.product.form.field.name.error'})),
            }))
        )),
        sku: yup
            .string()
            .required(props.intl.formatMessage({id: 'product.show.product.form.field.sku.error'})),
        attributes: lazy(obj => yup.object(attributesValidationSchema))
    });

    const initialValues = {
        'translations': product?.translations || getTranslationInitialValues(),
        'sku': product?.sku || '',
        'externalId': product?.externalId || null,
        'gender': product?.gender ? product.gender['@id'] : null,
        'brand': product?.brand ? product.brand['@id'] : null,
        'taxRate': product?.taxRate ? product.taxRate['@id'] : null,
        'categories': product.categories || [],
        'corners': product.corners || [],
        'productLock': product?.productLock || null,
        'attributes': product?.attributes.reduce((acc, attribute) => {
            acc[attribute.attribute.id] = attribute.value

            return acc;
        }, {})
    };

    async function handleLock(lockValues) {
        if (!lockValues) return;

        const initialLockValues = initialValues.productLock;

        if (!initialLockValues) {
            await createLock(product['@id'], lockValues)
        } else if (objectsHaveDifferentValues(initialLockValues, lockValues)) {
            await updateLock(lockValues['@id'], lockValues);
        }
    }

    function transformAttributes(attributes, productId) {
        return Object.entries(attributes)
            .filter(([attributeId, value]) => value !== '')
            .map(([attributeId, value]) => ({
                product: productId,
                attribute: attributeId,
                value: value
            }));
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            validateOnChange={true}
            validateOnBlur={true}
            validateOnMount={true}
            onSubmit={(values, {setSubmitting}) => {
                const transformedAttributes = transformAttributes(values.attributes, product.id);
                const payload = {
                    ...values,
                    attributes: transformedAttributes
                };
                update(product.id, payload).then(() => {
                    handleLock(values.productLock).finally(r => {
                        setSubmitting(false);
                        setSubmitted(true);
                        refreshProduct()
                    })
                })
            }}
            onReset={() => setSubmitted(false)}
            validate={() => setSubmitted(false)}
            setFieldValue
        >
            {({
                  isSubmitting,
                  isValid,
            }) => (
                <PageContent
                    titleId='product.show.product.form.title'
                    subtitleId='product.show.product.form.subtitle'
                    fullPage={true}
                    rightContent={(<LockProductButton />)}
                    paddingHeader={'24px 24px 0 24px'}
                    paddingBody={'48px 24px 24px 24px'}
                >
                    <FormikForm>
                        <ProductFormContext.Provider value={{
                            productLock: product?.productLock,
                            selectedLocale,
                            setSelectedLocale
                        }}>
                            <Grid container direction='column' rowSpacing={6}>
                                <Grid item container>
                                    <MainInformation />
                                </Grid>
                                <Grid item container>
                                    <ProductReferences />
                                </Grid>
                                {hasRequiredAttributes &&
                                    <Grid item container>
                                        <Attributes isRequired={true}/>
                                    </Grid>
                                }
                                <Grid item container>
                                    <Attributes />
                                </Grid>
                                <Grid item>
                                    <ActionsContainer>
                                        <CancelButtonStyled
                                            disabled={isSubmitting}
                                            onClick={() => history.goBack()}
                                        >
                                           <FormattedMessage id='product.show.product.form.actions.cancel' />
                                        </CancelButtonStyled>
                                        <SaveButton
                                            type='submit'
                                            disabled={isSubmitting || !isValid}
                                            loading={isSubmitting}
                                        >
                                            <FormattedMessage id='product.show.product.form.actions.submit' />
                                        </SaveButton>
                                    </ActionsContainer>
                                    {submitted &&
                                        <div style={{textAlign: "right", color: green500}}>
                                            <FormattedMessage id='product.show.product.form.actions.submit.success' />
                                        </div>
                                    }
                                </Grid>
                            </Grid>
                        </ProductFormContext.Provider>
                    </FormikForm>
                </PageContent>
            )}
        </Formik>
    );
}

export default injectIntl(ProductForm);
