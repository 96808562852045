import React from "react";
import {Route} from "react-router-dom";
import RequireAuthentication from "../hoc/authentication/requireAuthentication";
import Compliance from "../views/compliance";
import DAC7Create from "../views/compliance/components/DAC7/Create";
import DAC7Show from "../views/compliance/components/DAC7/Show";

let routes = [
    <Route
        path="/compliance"
        component={RequireAuthentication(Compliance)}
        exact
        strict={true}
        key="compliance"
    />,
    <Route
        path="/compliance/dac7/create"
        component={RequireAuthentication(DAC7Create)}
        exact
        strict={true}
        key="dac7Create"
    />,
    <Route
        path="/compliance/dac7/:id"
        component={RequireAuthentication(DAC7Show)}
        exact
        key="dac7Show"
    />,
];

export default routes;
