import {fetch, normalize} from "utils/dataAccess";

export function generateXml(id) {
    return new Promise((resolve, reject) => {
        fetch(`/d_a_c7_declarations/${id}/export`, {
            method: "POST",
        }).then(response => response.json()).then((retrieved) => {
            resolve(normalize(retrieved));
        }).catch(error => reject(error.message));
    });
}
