import React, {useState} from 'react';

import {Grid, Tab, Tabs} from "@mui/material";

import Page from "components/page/Page";

import DAC7 from "./components/DAC7";

function Compliance(props) {
    const [tab, setTab] = useState(0);

    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <Page titleId={'compliance.title'} fullPage>
            <Grid container rowSpacing={2} direction={'column'}>
                <Grid item>
                    <Tabs value={tab} onChange={handleChangeTab}>
                        <Tab label={"DAC7"}></Tab>
                    </Tabs>
                </Grid>
                <Grid item>
                    {tab === 0 && (
                        <DAC7 />
                    )}
                </Grid>
            </Grid>
        </Page>
    );
}

export default Compliance;
