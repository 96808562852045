const localStorageKeys = {
    productDataKey: 'productDataSE-v2',
    stepKey: 'stepSE'
};
export default class localStorageSE {
    static getProductData = () => {
        return JSON.parse(localStorage.getItem(localStorageKeys.productDataKey));
    }

    static getCurrentStep = () => {
        return JSON.parse(localStorage.getItem(localStorageKeys.stepKey));
    }

    static updateProductData = (productData) => {
        localStorage.setItem(localStorageKeys.productDataKey, JSON.stringify(productData));
    }

    static updateCurrentStep = (step) => {
        localStorage.setItem(localStorageKeys.stepKey, step);
    }

    static removeData = (key = null) => {
        if (key) {
            localStorage.removeItem(key);
        } else {
            localStorage.removeItem(localStorageKeys.productDataKey);
            localStorage.removeItem(localStorageKeys.stepKey);
        }
    }
}
