import React, {Component} from 'react';
import DrawerConfiguration from "../../../components/sidebar/lineItems/DrawerConfiguration";
import DrawerHelp from "../../../components/sidebar/lineItems/DrawerHelp";
import {ITEM_PER_PAGE} from "config/_pagination";
import DrawerRetailers from "../../../components/sidebar/lineItems/DrawerRetailers";
import {marketPlaceOffer} from "../../enum/offers";
import DrawerDashboard from "../../../components/sidebar/lineItems/DrawerDashboard";
import DrawerPwa from "../../../components/sidebar/lineItems/DrawerPwa";
import DrawerAccounting from "../../../components/sidebar/lineItems/DrawerAccounting";
import DrawerMerchandising from "../../../components/sidebar/lineItems/DrawerMerchandising";
import DrawerRetailerProfiles from "../../../components/sidebar/lineItems/DrawerRetailerProfiles";
import DrawerOptions from "../../../components/sidebar/lineItems/DrawerOptions";
import DrawerCompliance from "../../../components/sidebar/lineItems/DrawerCompliance";

class MarketPlaceMenuAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productTabOpen: this.props.match.path.startsWith("/retailers/show/:id/feeds/") || this.props.match.path.startsWith("/retailers/show/:id/products/") || this.props.match.path.startsWith("/retailers/show/:id/variations/"),
            itemsPerPage: this.props.itemsPerPage ? this.props.itemsPerPage : ITEM_PER_PAGE,
        };
    }

    handleProductTabChange = () => {
        this.setState(state => ({productTabOpen: !state.productTabOpen}));
        this.setState({userTabOpen: false});
    };

    render() {
        const {authorizations, classes, match, retailerRetrieved } = this.props;
        const {productTabOpen, itemsPerPage} = this.state;

        let dashboardLink = this.props.getDashboardLink;
        const isInDashboardPath = match.path === "/" || match.path === "/picking";

        return (
            <div>
                <DrawerDashboard
                    classes={classes}
                    dashboardLink={dashboardLink}
                    isInDashboardPath={isInDashboardPath}
                />
                <DrawerRetailers
                    itemsPerPage = {itemsPerPage}
                    productTabOpen = {productTabOpen}
                    authorizations = {authorizations}
                    match = {match}
                    retailerRetrieved = {retailerRetrieved}
                    classes = {classes}
                    handleProductTabChange={this.handleProductTabChange}
                    offer = {marketPlaceOffer}
                />
                <DrawerMerchandising
                    authorizations = {authorizations}
                    classes={classes}
                />
                <DrawerOptions
                    classes={classes}
                    match={match}
                    authorizations={authorizations}
                />
                <DrawerConfiguration
                    classes={classes}
                    match={match}
                    authorizations={authorizations}
                />
                <DrawerAccounting classes={classes}/>
                <DrawerCompliance
                    classes={classes}
                    authorizations={authorizations}
                />
                <DrawerHelp
                    classes={classes}
                    authorizations={authorizations}
                />
                <DrawerRetailerProfiles classes={classes} />
                <DrawerPwa classes={classes} />
            </div>
        )
    }
}

export default MarketPlaceMenuAdmin;
