import React, {useState} from 'react';
import {injectIntl} from "react-intl";

import PropTypes from 'prop-types';
import Badge from "components/Badge";

StatusBadge.propTypes = {
    status: PropTypes.string.isRequired
};

function StatusBadge({status, ...props}) {
    const [statusColor] = useState(getStatusColor());

    function getStatusColor() {
        switch (status) {
            case 'processing':
                return 'warning';
            case 'reviewed':
                return 'online';
            case 'finished':
                return 'success';
            case 'to-review':
            default:
                return 'default';
        }
    }

    return (
        <Badge
            label={props.intl.formatMessage({id: `compliance.dac7.list.table.status.${status}`})}
            size={'large'}
            color={statusColor}
        />
    );
}

export default injectIntl(StatusBadge);
