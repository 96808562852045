import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl, FormattedMessage} from "react-intl";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {useFormikContext} from "formik";

import Label from "components/input/new/Label";
import {CKEditorConfig} from "components/input/inputRenderer/wysiwygInputRenderer";

import LockFieldButton from "../../../../LockFieldButton";

import {UserAgentUtils} from "utils/useragent";

DescriptionField.propTypes = {
    localeCode: PropTypes.string.isRequired
};

function DescriptionField({localeCode, ...props}) {
    const {values, setFieldValue} = useFormikContext();

    const cloneConfig = {...CKEditorConfig};

    const customCKEditorConfig = Object.assign(cloneConfig, {
        toolbar: {items: CKEditorConfig.toolbar, shouldNotGroupWhenFull: true},
        link: {addTargetToExternalLinks: true},
    });

    function lockButtonContainerStyle() {
        if (UserAgentUtils.isMobile()) {
            return {
                position: 'absolute',
                top: '-29px',
                left: '64px'
            }
        } else {
            return {
                position: 'absolute',
                top: '4px',
                right: '8px'
            }
        }
    }

    return (
        <>
            <Label htmlFor={`translations.${localeCode}.description`}>
                <FormattedMessage id='product.show.product.form.field.description' />
            </Label>
            <div style={{position: "relative", width: '100%'}}>
                <CKEditor
                    name={`translations.${localeCode}.description`}
                    type={"wysiwyg"}
                    config={customCKEditorConfig}
                    editor={ClassicEditor}
                    data={values.translations?.[localeCode]?.description ?? ''}
                    placeholder={(<FormattedMessage id='product.show.product.form.field.description.placeholder' />)}
                    onChange={( event, editor ) => {
                        setFieldValue(`translations.${localeCode}.description`, editor.getData());
                    }}
                />
                <div style={lockButtonContainerStyle()}>
                    <LockFieldButton
                        isLocked={values.productLock?.description}
                        onClick={() => setFieldValue('productLock.description', !values.productLock?.description)}
                    />
                </div>
            </div>

        </>
    );
}

export default injectIntl(DescriptionField);
