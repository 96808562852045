import React from "react";
import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";
import {Route, Switch} from "react-router-dom";
import {ConnectedRouter} from "connected-react-router";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from '@sentry/browser';
import * as moment from "moment";
import Cookies from "js-cookie";
import 'react-app-polyfill/stable';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import {StoreContext} from 'contexts/storeContext';
import GA4React from 'ga-4-react';
import {default as store, history} from './store';
import { hotjar } from "react-hotjar";

// styles
import "typeface-roboto";
import CssBaseline from "@mui/material/CssBaseline";
import './assets/css/dateRangePickerOverrides.css';
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import {dangerColor, primaryColor} from "./assets/jss/main";

// core components
import RequireAuthentication from "./hoc/authentication/requireAuthentication";
import HandleApplicationRoot from "./hoc/application/handleApplicationRoot";
import RetailerUnloader from "./hoc/retailer/retailerUnloader";
import Dashboard from "./views/marketplace/dashboard";
import Error404Page from "./views/error404Page";
import ErrorPage from "./views/errorPage";

// routes
import authenticationRoutes from "./routes/authentication";
import authenticationV2Routes from "./routes/authenticationV2";
import accoutingRoutes from "./routes/accouting";
import retailerRoutes from "./routes/marketplace/retailer";
import feedRoutes from "./routes/marketplace/feed";
import merchandisingRoutes from "./routes/marketplace/merchandising";
import productRoutes from "./routes/marketplace/product";
import pickingOrderRoutes from "./routes/picking/order";
import pickingDashboardRoutes from "./routes/picking/dashboard";
import pickingRetailerRoutes from "./routes/picking/retailer";
import profileRoutes from "./routes/marketplace/profile";
import memberRoutes from "./routes/configuration/members";
import rolesRoutes from "./routes/configuration/roles";
import categoriesRoutes from "./routes/configuration/categories";
import cornersRoutes from "./routes/configuration/corners";
import gendersRoutes from "./routes/configuration/genders";
import sectorsRoutes from "./routes/configuration/sectors";
import stockEasyRoutes from "./routes/stockeasy/product";
import statisticsRoutes from "./routes/statistics/dashboard";
import quoteRoutes from "./routes/quote";
import cancellationReasonsRoutes from "./routes/configuration/cancellationReasons";
import recommendationsRoutes from "./routes/configuration/recommendations";
import retailerProfilesRoutes from "./routes/retailerProfile";
import printerRoutes from "./routes/picking/printers";
import optionRoutes from "./routes/configuration/options";
import attributesRoutes from "./routes/configuration/attributes";
import complianceRoutes from "./routes/compliance";

// translations
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/fr';
import {IntlProvider} from "react-intl";
import {translations as momentTranslations} from "./assets/translations/moment";
import {language, translations} from "./assets/translations/messages";

//redux-devtools-extension
import {fetch} from "./utils/dataAccess";
import {getSessionOrganizations} from './actions/authentication';

//utils
import pusherClient from "utils/pusherClient";
import {getMemberRetailers} from "./actions/authentificationMember";

Sentry.init({dsn: process.env.REACT_APP_SENTRY_DSN});

window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    window.installWishibamApplicationPrompt = e;
});

moment.locale("fr", momentTranslations[language]);

const token = Cookies.get("_f") ? JSON.parse(Cookies.get("_f").toString()) : null;
const member = Cookies.get("_c") ? JSON.parse(Cookies.get("_c").toString()) : null;

let protocol = window.location.protocol;
let host = window.location.host;
let pathname = window.location.pathname;
let parts = host.split(":");
let applicationsAccess =null;

const { REACT_APP_GOOGLE_ANALYTICS_ID, REACT_APP_HOTJAR_ID } = process.env;
const ga4react = new GA4React(REACT_APP_GOOGLE_ANALYTICS_ID);

if ('' !== REACT_APP_HOTJAR_ID) {
    hotjar.initialize(REACT_APP_HOTJAR_ID, 6);
}

ga4react.initialize().then((ga4) => {
    ga4.pageview(window.location.pathname);
    history.listen((location) => {
        ga4.pageview(location.pathname);
    });
},(err) => {
    console.error(err);
});

if (process.env.REACT_APP_RETURN_ENABLED) {
    console.log("Return feature is enabled");
}

if (token && member) {
    const organizations = getSessionOrganizations();
    const authorizations = Cookies.get("_b") ? JSON.parse(Cookies.get("_b").toString()) : null;
    const invitations = Cookies.get("_e") && "undefined" !== Cookies.get("_e") ? JSON.parse(Cookies.get("_e").toString()) : null;
    applicationsAccess = Cookies.get("_a") ? JSON.parse(Cookies.get("_a").toString()) : null;
    let subdomain = organizations[member["organization"]]["subdomain"];
    let organizationId = member["organization"];

    localStorage.setItem('organizationId', organizationId);

    // Load current organization asap
    fetch("/organizations/" + member['organization'], {signal: null}).then(response => {
        response.json().then(retrieved => {
            store.dispatch({
                type: "ORGANIZATION_CURRENT",
                retrieved: retrieved
            });
        });
    });

    // subscribe user to his own private channel and fetch notifications from DB
    const memberChannel = pusherClient.subscribe(member["id"]);
    const userChannel = pusherClient.subscribe(member["userId"]);

    const addNewNotification = (event, data) => {
        store.dispatch({
            type: "ADD_NEW_NOTIFICATION",
            notification: {type: event, data: data}
        });
    }

    memberChannel.bind('error', (data) => addNewNotification('error', data));
    memberChannel.bind('import', (data) => addNewNotification('import', data));
    memberChannel.bind('synchronization', (data) => addNewNotification('synchronization', data));
    userChannel.bind('change_organization', (data) => {
        const newOrga = organizations[data.currentOrganization];
        if (!!newOrga) {
            const partsOfHostname = window.location.hostname.split('.');
            const match = partsOfHostname.find((part) => part === newOrga.subdomain)

            // If the user is on an url of the new organization domain don't force the reload.
            if (match) {
                return;
            }
        }

        window.location.href = '/'
    });

    fetch(`/notifications?pagination=false&order[createdAt]=desc&organizationId=${organizationId}`, {signal: null}).then(response => {
        response.json().then(retrieved => {
            store.dispatch({
                type: "NOTIFICATION_LIST_SUCCESS",
                retrieved: retrieved["hydra:member"]
            })
        })
    });

    if (invitations.length > 0 && !pathname.startsWith('/invitation/')) {
        let url = protocol + "//" + subdomain + "." + process.env.REACT_APP_HOST;

        // in-case we have a port number in the host url. (Dev environment mostly)
        if (parts.length === 2) {
            url += ":" + parts[1]
        }

        window.location = url + `/invitation/name=${invitations[0]['organization']['name']}&id=${invitations[0]['organization']['id']}&logo=${invitations[0]['organization']['logo'] ? invitations[0]['organization']['logo'] : ''}&marketplace=${invitations[0]['applications']['marketplace']}&picking=${invitations[0]['applications']['picking']}&personalShopper=${invitations[0]['applications']['personalShopper']}`;
    }

    if (subdomain + "." + process.env.REACT_APP_HOST !== parts[0]) {
        let url = protocol + "//" + subdomain + "." + process.env.REACT_APP_HOST;

        // in-case we have a port number in the host url. (Dev environment mostly)
        if (parts.length === 2) {
            url += ":" + parts[1]
        }

        if (/\/retailers\/stripe\/confirm\/retailer\/.+?$/.test(pathname)) {
            window.location = url + window.location.pathname + window.location.search;
        } else if (!pathname.startsWith('/invitation/')) {
            window.location = url;
        }
    }

    store.dispatch({
        type: "LOGIN_SUCCESS_MEMBER",
        member: member
    });

    store.dispatch({
        type: "LOGIN_SUCCESS_TOKEN",
        token: token
    });

    store.dispatch({
        type: "LOGIN_SUCCESS_ORGANIZATIONS",
        organizations: organizations
    });

    store.dispatch({
        type: "LOGIN_SUCCESS_APPLICATIONS",
        applications: applicationsAccess
    });

    store.dispatch({
        type: "LOGIN_SUCCESS_AUTHORIZATIONS",
        authorizations: authorizations
    });

    store.dispatch({
        type: "LOGIN_SUCCESS_INVITATIONS",
        invitations: invitations
    });

    if (
        member['stripeAccounts'] &&
        !pathname.startsWith('/signupStripe') &&
        !/\/retailers\/stripe\/confirm\/retailer\/.+?$/.test(pathname) &&
        !pathname.startsWith('/error')
    ) {
        Object.keys(member['stripeAccounts']).forEach(key => {
            if (false === member['stripeAccounts'][key]['hasAccount'] && true === member['stripeAccounts'][key]['needsAccount']) {
                let url = protocol + "//" + subdomain + "." + process.env.REACT_APP_HOST;
                // in-case we have a port number in the host url. (Dev environment mostly)
                if (parts.length === 2) {
                    url += ":" + parts[1]
                }

                setTimeout(function() {
                    return window.location.href = url + '/signupStripe';
                }, 100);
            }
        });
    }
} else {
    let url = "auth." + process.env.REACT_APP_HOST;

    if (parts[0] !== url) {
        // in-case we have a port number in the host url. (For dev environment)
        if (parts.length === 2) {
            url += ":" + parts[1]
        }

        if (!window.location.href.includes(protocol + "//" + url + "/404")) {
            window.location = protocol + "//" + url + "/login";
        }
    }
}

document.body.style.cssText = "background-color: #f8f9fa;font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;";

const palette = createTheme({
    palette: {
        primary: {main: primaryColor},
        secondary: {main: dangerColor},
    },
    overrides: {
        // for stepper
        MuiStepIcon: {
            root: {
                // color: 'white',
                '&.Mui-active': {
                    color: '#f44336',
                },
            },
        },
    },
});
const retailers = getMemberRetailers();

const container = document.getElementById('root')
const root = createRoot(container);

root.render(
    <React.Fragment>
        <CssBaseline>
            <Provider store={store}>
                <StoreContext.Provider value={store}>
                    <IntlProvider locale={language} messages={translations[language]} defaultLocale={'fr'}>
                        <StyledEngineProvider injectFirst>
                            <ThemeProvider theme={palette}>
                                <ConnectedRouter history={history}>
                                    <Switch>
                                        {(applicationsAccess && applicationsAccess["marketplace"] && (null === retailers || retailers?.length > 1)) ?
                                            <Route path="/" component={RequireAuthentication(RetailerUnloader(Dashboard))}
                                                   strict={true} exact={true}/> :
                                            <Route path="/" component={HandleApplicationRoot} strict={true} exact={true}/>
                                        }
                                        {authenticationRoutes}
                                        {authenticationV2Routes}
                                        {feedRoutes}
                                        {merchandisingRoutes}
                                        {retailerRoutes}
                                        {productRoutes}
                                        {pickingDashboardRoutes}
                                        {pickingOrderRoutes}
                                        {pickingRetailerRoutes}
                                        {profileRoutes}
                                        {memberRoutes}
                                        {rolesRoutes}
                                        {categoriesRoutes}
                                        {cornersRoutes}
                                        {gendersRoutes}
                                        {sectorsRoutes}
                                        {stockEasyRoutes}
                                        {statisticsRoutes}
                                        {accoutingRoutes}
                                        {quoteRoutes}
                                        {cancellationReasonsRoutes}
                                        {recommendationsRoutes}
                                        {retailerProfilesRoutes}
                                        {printerRoutes}
                                        {optionRoutes}
                                        {attributesRoutes}
                                        {complianceRoutes}
                                        {/* Add your routes here */}
                                        <Route path='/404' component={RetailerUnloader(Error404Page)}/>
                                        <Route path='/error' component={RetailerUnloader(ErrorPage)}/>
                                    </Switch>
                                </ConnectedRouter>
                            </ThemeProvider>
                        </StyledEngineProvider>
                    </IntlProvider>
                </StoreContext.Provider>
            </Provider>
        </CssBaseline>
    </React.Fragment>
);

serviceWorker.register({
    onUpdate: registration => {
        const waitingServiceWorker = registration.waiting

        if (waitingServiceWorker) {
            waitingServiceWorker.addEventListener("statechange", event => {
                if (event.target.state === "activated") {
                    window.location.reload();
                }
            });
            waitingServiceWorker.postMessage({type: "SKIP_WAITING"});
        }
    }
});
