import {fetch, normalize} from "utils/dataAccess";

export default function update(dac7Declaration, values) {
    return new Promise((resolve, reject) => {
        fetch(dac7Declaration['@id'], {
            method: "PATCH",
            body: JSON.stringify(values),
            headers: new Headers({'Content-Type': 'application/merge-patch+json'}),
        }).then(response => response.json()).then((retrieved) => {
            resolve(normalize(retrieved));
        }).catch(error => reject(error));
    });
}
