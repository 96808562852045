import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {FormattedDate, FormattedMessage} from "react-intl";

import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow
} from "@mui/material";

import SaveButton from "components/button/SaveButton";
import BeatLoaderRegular from "components/loaders/BeatLoaderRegular";

import TableHead from "./components/TableHead";
import StatusBadge from "./components/StatusBadge";

import list from "api/compliance/dac7/list";

function Dac7List(props) {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [declarations, setDeclarations] = useState([]);

    useEffect(() => {
        setLoading(true);
        list().then((r) => setDeclarations(r)).finally(() => setLoading(false));
    }, []);

    function showDeclaration(declaration) {
        // avoid redirection if user is selecting text
        if (window.getSelection().toString().length > 0) {
            return;
        }

        history.push(`/compliance/dac7/${declaration.id}`)
    }

    return (
        <Grid container rowSpacing={2} direction={'column'}>
            <Grid item>
                <SaveButton onClick={() => history.push('/compliance/dac7/create')}>
                    <FormattedMessage id={'compliance.list.create'} />
                </SaveButton>
            </Grid>
            <Grid item>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead />
                        <TableBody>
                            {loading && (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        <BeatLoaderRegular loading={true} />
                                    </TableCell>
                                </TableRow>
                            )}
                            {!loading && declarations.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        <FormattedMessage id={'compliance.list.empty'} />
                                    </TableCell>
                                </TableRow>
                            )}
                            {!loading && declarations.map((declaration) => (
                                <TableRow
                                    hover
                                    key={declaration.name}
                                    onClick={() => showDeclaration(declaration)}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        cursor: 'pointer'
                                    }}
                                >
                                    <TableCell component="th" scope="row" >
                                        <b>{declaration.name}</b>
                                    </TableCell>
                                    <TableCell>
                                        <FormattedDate
                                            day={'numeric'}
                                            month={'long'}
                                            year={'numeric'}
                                            value={declaration.createdAt}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <StatusBadge status={declaration.status} />
                                    </TableCell>
                                    <TableCell>
                                        <FormattedMessage id={`compliance.dac7.form.country.${declaration.declarationCountry}`} />
                                    </TableCell>
                                    <TableCell>
                                        <FormattedMessage id={`compliance.dac7.form.country.${declaration.destinationCountry}`} />
                                    </TableCell>
                                    <TableCell>{declaration.period}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}

export default Dac7List;
