import React, {Component} from "react";
import connect from "react-redux/lib/connect/connect";
import {FormattedMessage, injectIntl} from "react-intl";
import { v4 as uuidv4 } from 'uuid';

// @mui/material components
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";

// core components
import BarcodeScanner from "../../../../../../../components/barcodeScanner";
import Button from "../../../../../../../components/button/button";
import CustomCreatableSelect from "../../../../../../../components/select/creatableSelect";
import GridContainer from "../../../../../../../components/grid/gridContainer";
import GridItem from "../../../../../../../components/grid/gridItem";

// style
import CreateProductFormStyle from "../../../../../../../assets/jss/views/stockeasy/product/create/form/createProductFormStyle";
import { ReactComponent as BarcodeScannerIcon } from 'assets/img/barcode_scanner.svg';

import {formatOptions} from "../../form/selectHelper";
import {withStyles} from "@mui/styles";

// utils
import {UserAgentUtils} from "../../../../../../../utils/useragent";
import {getTranslation} from "../../../../../../../domain/helpers/translations";

import localStorageSE from "../../localStorageManager/localStorageSE";
import {redirectToStockEasyApp} from "../../../../../../../utils/redirectToStockEasyApp";

class CreateVariationModalContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            barcode: props.selectedVariation ? props.selectedVariation.barcode : props.barcode ?? '',
            sku: props.selectedVariation ? props.selectedVariation.sku : uuidv4(),
            weight: props.selectedVariation ? props.selectedVariation.weight : null,
            barcodeErrorMessage: null,
            barcodeScanActive: false,
            options: props.selectedVariation ? props.selectedVariation.options : {},
        }
    }

    onBarcodeChange(barcode) {
        this.setState({
            barcode: barcode,
        });
    }

    onOptionChange(option, value) {
        let options = this.state.options;
        options[option] = value.label;

        let filteredOptions = Object.keys(options)
            .filter(key => options[key] && options[key].trim() !== "")
            .reduce((res, key) => (res[key] = options[key], res), {});

        let sortedOptions = Object.keys(filteredOptions)
            .sort()
            .reduce((res, key) => {
                res[key] = filteredOptions[key];
                return res;
            }, {});

        this.setState({options: sortedOptions});
    }

    onWeightChange(event) {
        this.setState({
            weight: event.target.value,
        });
    }

    isBarcodeValid(barcode) {
        const {intl, selectedVariation} = this.props;
        const productData = localStorageSE.getProductData();

        // if it's not a barcode but a sku
        if (productData.variations[0] && productData.variations[0].sku !== null && productData.variations[0].barcode === null) {
            return true;
        }

        if (!barcode) {
            this.setState({
                barcodeErrorMessage: intl.formatMessage({id: "stockeasy.color.validation.barcode.exists"})
            });

            return false;
        }

        // no change on selected variation
        if (selectedVariation && selectedVariation.barcode === barcode) {
            return true;
        }

        const regex = new RegExp('^[0-9]{13}$');

        if (!regex.test(barcode)){
            this.setState({
                barcodeErrorMessage: intl.formatMessage({id: "stockeasy.color.validation.barcode.length"})
            });

            return false;
        }

        const duplicate = productData.variations.some((variation) => variation.sku === barcode);

        if (duplicate) {
            this.setState({
                barcodeErrorMessage: intl.formatMessage({id: "stockeasy.color.validation.barcode.duplicate"})
            });

            return false;
        }

        return true;
    }

    isWeightValid(weight) {
        const {intl} = this.props;

        if (null === weight || weight === '' || weight <= 0 || !weight) {
            this.setState({
                barcodeErrorMessage: intl.formatMessage({id: "stockeasy.weight.error.empty"})
            });

            return false;
        }

        return true;
    }

    isVariationExist(options) {
        const {productData, intl, selectedVariation} = this.props;

        // if no change on selected variation
        if (selectedVariation && (JSON.stringify(options) === JSON.stringify(selectedVariation.options))) {
            return false;
        }

        const variationExist = productData.variations.some((variation) => variation.barcode !== variation.options === options);

        if (variationExist) {
            this.setState({
                barcodeErrorMessage: intl.formatMessage({id: "stockeasy.color.validation.color_size.uniqueness.error"})
            });

            return true;
        }

        return false;
    }

    persistVariation() {
        const {barcode, options, sku, weight} = this.state;
        const productData = localStorageSE.getProductData();

        const isBarcodeValid = this.isBarcodeValid(barcode);
        const isWeightValid = this.isWeightValid(weight);
        const isVariationExist = this.isVariationExist(options);

        if ((isBarcodeValid || (sku !== null && productData.isProductWithoutBarCode)) && isWeightValid && isVariationExist === false) {
            this.props.submit(barcode, options, sku, weight)
        }
    }

    handleBarcodeScanned(barcode) {
        this.setState({
            barcodeScanActive: false,
            barcode: barcode,
        });
    }

    renderBarcodeScanner() {
        const {barcodeScanActive} = this.state;
        const productData = localStorageSE.getProductData();
        const isVariationWithoutBarcode = productData.isProductWithoutBarCode;
        if (!UserAgentUtils.isMobileOrTablet() || isVariationWithoutBarcode || !barcodeScanActive) {
            return;
        }

        return (
            <Dialog
                open={barcodeScanActive}
                aria-labelledby="responsive-dialog-title"
                onClose={() => this.setState({barcodeScanActive: false})}
            >
                <DialogContent>
                    <BarcodeScanner
                        hideButtons
                        onBarcodeFound={(barcode) => this.handleBarcodeScanned(barcode)}
                        onExit={() => this.setState({barcodeScanActive: false})}
                        allowLoopScanning={true}
                    />
                </DialogContent>
            </Dialog>
        );
    }

    renderOptionSelect() {
        const {classes, intl, options} = this.props;

        if (!options) {
            return;
        }

        return (
            Object.keys(options).map(key => {
                const item = options[key];

                const availableOptions = item.retailerOptionsValue.map(option => { return {label: getTranslation(option).name, value: option.id} });

                return (
                    <CustomCreatableSelect
                        key={key}
                        id={item.option.code}
                        label={getTranslation(item.option).name}
                        onChange={(option) => this.onOptionChange(item.option.id, option)}
                        options={availableOptions}
                        value={this.state.options && this.state.options[item.option.id] ? formatOptions([this.state.options[item.option.id]]) : null}
                        maxMenuHeight={250}
                        formatCreateLabel={(name) => intl.formatMessage({id: "stockeasy.options.add"}, {value: name})}
                        className={classes.textField}
                        noOptionsMessage={intl.formatMessage({id: "stockeasy.options.add_value"})}
                        placeholder={intl.formatMessage({id: "stockeasy.options.select_value"})}
                    />
                );
            })
        )
    }

    render() {
        const {classes, intl, selectedVariation} = this.props;
        const productData = localStorageSE.getProductData();
        const isVariationWithoutBarcode = productData.isProductWithoutBarCode;
        const isSelectedVariationWithBarcode = selectedVariation && selectedVariation.barcode;

        return (
            <div>
                <DialogTitle id="form-dialog-title">
                    <FormattedMessage id={"stockeasy.variation_create.dialog.title"} />
                </DialogTitle>

                {this.renderBarcodeScanner()}
                <DialogContent className={classes.dialogContentBarcode}>
                    <DialogContentText className={classes.dialogContentAlert}>
                        <FormattedMessage id={"stockeasy.variation_create.dialog.notice"} />
                    </DialogContentText>
                    {isVariationWithoutBarcode ?
                        <TextField
                            variant="standard"
                            id="sku"
                            label={intl.formatMessage({id: "stockeasy.sku.label"})}
                            autoFocus
                            margin="dense"
                            type="text"
                            fullWidth
                            disabled={true}
                            defaultValue={selectedVariation && selectedVariation.sku ? selectedVariation.sku : this.state.sku}
                            required />
                        :
                        (<div>
                            {(UserAgentUtils.isMobileOrTablet() && !isSelectedVariationWithBarcode ) && (
                                <Button
                                    round
                                    className={classes.scanButton}
                                    onClick={() => redirectToStockEasyApp(true) }
                                >
                                    <BarcodeScannerIcon className={classes.scanIcon}/>
                                </Button>
                            )}
                            <TextField
                                variant="standard"
                                id="barcode"
                                label={intl.formatMessage({id: "stockeasy.barcode.label"})}
                                autoFocus
                                margin="dense"
                                type="text"
                                fullWidth
                                disabled={selectedVariation && selectedVariation.default}
                                defaultValue={this.state.barcode || (selectedVariation && selectedVariation.barcode)}
                                onChange={(e) => this.onBarcodeChange(e.target.value)}
                                required />
                        </div>
                        )
                    }
                    {this.renderOptionSelect()}
                    <TextField
                      id="weight"
                      label={intl.formatMessage({id: "stockeasy.variation_create.weight"})}
                      placeholder={intl.formatMessage({id: "stockeasy.variation_create.weight.placeholder"})}
                      variant="outlined"
                      type="number"
                      fullWidth
                      value={this.state.weight}
                      className={classes.modalTextField}
                      onChange={(event) => { this.onWeightChange(event) }}
                      InputLabelProps={{shrink: true}}
                      required
                    />
                </DialogContent>
                <DialogActions className={classes.dialogVariationActions}>
                    <GridContainer className={classes.buttonsWrapper}>
                        <GridItem className={classes.buttonWrapper}>
                            <Button
                                round
                                color={"success"}
                                onClick={() => this.persistVariation()}
                                className={classes.buttonAction}
                            >
                                {intl.formatMessage({id:"stockeasy.barcode.dialog.button"})}
                            </Button>
                        </GridItem>
                        {(selectedVariation && selectedVariation.default === false) && (
                            <GridItem className={classes.buttonWrapper}>
                                <Button
                                    round
                                    color={"danger"}
                                    onClick={() => this.props.delete()}
                                    className={classes.buttonAction}
                                >
                                    {intl.formatMessage({id:"stockeasy.color.sizes.button.delete"})}
                                </Button>
                            </GridItem>
                        )}
                    </GridContainer>
                    {this.state.barcodeErrorMessage !== null && (
                        <span className={classes.error}>
                            {this.state.barcodeErrorMessage}
                        </span>
                    )}
                </DialogActions>
            </div>
        );
    }
}

export default connect()(withStyles(CreateProductFormStyle)(injectIntl(CreateVariationModalContent)));
