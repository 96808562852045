import React from 'react';
import {FormattedMessage} from "react-intl";

import {TableHead as MuiTableHead, TableCell, TableRow} from "@mui/material";

function TableHead(props) {
    return (
        <MuiTableHead>
            <TableRow>
                <TableCell><FormattedMessage id={'compliance.dac7.list.table.head.name'} /></TableCell>
                <TableCell><FormattedMessage id={'compliance.dac7.list.table.head.createdAt'} /></TableCell>
                <TableCell><FormattedMessage id={'compliance.dac7.list.table.head.status'} /></TableCell>
                <TableCell><FormattedMessage id={'compliance.dac7.list.table.head.declarationCountry'} /></TableCell>
                <TableCell><FormattedMessage id={'compliance.dac7.list.table.head.destinationCountry'} /></TableCell>
                <TableCell><FormattedMessage id={'compliance.dac7.list.table.head.period'} /></TableCell>
            </TableRow>
        </MuiTableHead>
    );
}

export default TableHead;
