import React, {useEffect, useState} from 'react';
import {FormattedMessage} from "react-intl";
import {useHistory, useParams} from "react-router-dom";

import {
    Grid,
    Step,
    StepContent,
    StepLabel,
    Stepper
} from "@mui/material";

import Page from "components/page/Page";
import SaveButton from "components/button/SaveButton";
import CancelButtonStyled from "components/button/CancelButtonStyled";
import FormTitle from "components/form/FormTitle";

import DeclarationResume from "./components/DeclarationResume";

import show from "api/compliance/dac7/show";
import update from "api/compliance/dac7/update";
import {generateXml} from "api/compliance/dac7/generateXml";

function DAC7Show(props) {
    const history = useHistory();

    const [activeStep, setActiveStep] = useState(0);
    const [declaration, setDeclaration] = useState(null);
    const [generateLoading, setGenerateLoading] = useState(false);

    const {id} = useParams();

    useEffect(() => {
        show(id).then((r) => {
            setDeclaration(r);
            guessTheStep(r);
        });
    }, []);

    useEffect(() => {
        if (declaration !== null) {
            guessTheStep(declaration);
        }
    }, [declaration]);

    if (declaration === null) {
        return null;
    }

    function guessTheStep(declaration) {
        if (!declaration) return;

        if (declaration.status === 'to-review' && declaration?.retailerExportCsvUrl !== null)  {
            setActiveStep(0)
        } else if (declaration.status === 'reviewed') {
            setActiveStep(1)
        } else if (declaration.status === 'processing') {
            setActiveStep(2)
        } else if (declaration.status === 'finished' && declaration?.generatedXmlUrl !== null) {
            setActiveStep(3)
        }
    }

    function openUrlInOtherTab(url) {
        window.open(url, '_blank', 'noreferrer,noopener');''
    }

    function generateXmlTrigger() {
        setGenerateLoading(true);
        generateXml(declaration.id)
            .then((r) => setDeclaration(r))
            .finally(() => setGenerateLoading(false))
        ;
    }
    return (
        <Page titleId={declaration.name}>
            <Grid
                container
                item
                xs={12}
                lg={6}
                justifyContent={'center'}
                rowSpacing={4}
                direction={'column'}
            >
                <Grid item>
                    <DeclarationResume declaration={declaration} />
                </Grid>
                <Grid item container rowSpacing={2} direction={'column'}>
                    <Grid item>
                        <FormTitle titleId={'compliance.dac7.show.generate.title'}/>
                    </Grid>
                    <Grid item>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            <Step key={"csv"}>
                                <StepLabel>
                                    <FormattedMessage id={'compliance.dac7.show.generate.step.csv.label'} />
                                </StepLabel>
                                <StepContent>
                                    <FormattedMessage id={'compliance.dac7.show.generate.step.csv.content'} />
                                    <SaveButton
                                        onClick={() => {
                                            openUrlInOtherTab(declaration?.retailerExportCsvUrl)
                                            update(declaration, {'status': 'reviewed'}).then((r) => setDeclaration(r) )
                                        }}
                                        sx={{marginTop:'12px'}}
                                    >
                                        <FormattedMessage id={'compliance.dac7.show.generate.step.csv.button'} />
                                    </SaveButton>
                                </StepContent>
                            </Step>
                            <Step key={"xml"}>
                                <StepLabel>
                                    <FormattedMessage id={'compliance.dac7.show.generate.step.xml.label'} />
                                </StepLabel>
                                <StepContent>
                                    <FormattedMessage id={'compliance.dac7.show.generate.step.xml.content'} />
                                    <SaveButton
                                        loading={generateLoading}
                                        onClick={() => generateXmlTrigger()}
                                        sx={{marginTop:'12px'}}
                                    >
                                        <FormattedMessage id={'compliance.dac7.show.generate.step.xml.button'} />
                                    </SaveButton>
                                </StepContent>
                            </Step>
                            <Step key={'progress'}>
                                <StepLabel>
                                    <FormattedMessage id={'compliance.dac7.show.generate.step.progress.label'} />
                                </StepLabel>
                                <StepContent>
                                    <FormattedMessage id={'compliance.dac7.show.generate.step.progress.content'} />
                                </StepContent>
                            </Step>
                            <Step key={'finished'}>
                                <StepLabel>
                                    <FormattedMessage id={'compliance.dac7.show.generate.step.finished.label'} />
                                </StepLabel>
                                <StepContent>
                                    <FormattedMessage id={'compliance.dac7.show.generate.step.finished.content'} />
                                    <SaveButton
                                        onClick={() => openUrlInOtherTab(declaration?.generatedXmlUrl)}
                                        sx={{marginTop:'12px'}}
                                    >
                                        <FormattedMessage id={'compliance.dac7.show.generate.step.finished.button'} />
                                    </SaveButton>
                                </StepContent>
                            </Step>
                        </Stepper>
                    </Grid>
                </Grid>
                <Grid item container justifyContent={'flex-end'}>
                    <CancelButtonStyled
                        outlined={true}
                        onClick={() => history.push('/compliance')}
                    >
                        <FormattedMessage id={'compliance.dac7.show.actions.cancel'} />
                    </CancelButtonStyled>
                </Grid>
            </Grid>
        </Page>
    );
}

export default DAC7Show;
