import React from "react";
import {Route} from "react-router-dom";
import Cookies from "js-cookie";

import RequireAuthentication from "hoc/authentication/requireAuthentication";

import Attributes from "views/attributes";

let routes = []
const token = Cookies.get("_f") ? JSON.parse(Cookies.get("_f").toString()) : null

if (token) {
    routes.push([
        <Route
            path="/attributes"
            component={RequireAuthentication(Attributes)}
            key="attributes"
            exact
        />,
    ])
}

export default routes
