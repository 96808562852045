import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from "react-intl";
import {useFormikContext} from "formik";

import Label from "components/input/new/Label";
import InputTextField from "components/input/new/InputTextField";
import {getTranslation} from "../../../../../../../../../domain/helpers/translations";
import StyledReactSelect from "../../../../../../../../../components/select/StyledReactSelect";
import FormControlLabel from "../../../../../../../../../components/radioGroup/FormControlLabel";
import RadioGroup from "../../../../../../../../../components/radioGroup/RadioGroup";

AttributeField.propTypes = {
    attribute: PropTypes.object,
};


function AttributeField({name, attribute, ...props}) {
    const {values, handleChange, handleBlur, touched, errors, setFieldValue} = useFormikContext()

    return (
        <>
            <Label required={!!attribute.required} htmlFor={getTranslation(attribute).name}>
                {getTranslation(attribute).name}
            </Label>
            {(attribute.type === 'string' || attribute.type === 'int' || attribute.type === 'float' || attribute.type === 'url') ?
                <InputTextField
                    name={`attributes.${attribute.id}`}
                    onChange={(e) => {
                        let value = e.target.value;
                        if (attribute.type === 'float') {
                            value = value.replace(',', '.');
                        }
                        handleChange(e);
                        setFieldValue(`attributes.${attribute.id}`, value);
                    }}
                    value={values.attributes[attribute.id] || ''}
                    placeholder={
                        props.intl.formatMessage({id: attribute.type === 'url' ? 'product.show.product.form.field.url.placeholder' : 'product.show.product.form.field.name.placeholder'})
                    }
                    fullWidth
                    onBlur={handleBlur}
                    error={touched.attributes?.[attribute.id] && Boolean(errors.attributes?.[attribute.id])}
                    helperText={touched.attributes?.[attribute.id] && errors.attributes?.[attribute.id] ? errors.attributes[attribute.id] : ''}
                />
            : (attribute.type === 'multiple_choice' && attribute.list.length) ?
                    <StyledReactSelect
                        isMulti
                        placeholder={<FormattedMessage id='product.show.product.form.field.attribute.multiselect.placeholder' />}
                        name={`attributes.${attribute.id}`}
                        value={attribute.list.filter(item => values.attributes[attribute.id]?.includes(item)).map(item => ({value: item, label: item}))}
                        options={attribute.list.map((item) => ({value: item, label: item}))}
                        onChange={(selectedList) => {setFieldValue(`attributes.${attribute.id}`, selectedList ? selectedList.filter(item => attribute.list.includes(item.label)).map(item => item.label) : [])}}
                        onBlur={handleBlur}
                        error={touched.attributes?.[attribute.id] && Boolean(errors.attributes?.[attribute.id])}
                        helperText={touched.attributes?.[attribute.id] && errors.attributes?.[attribute.id] ? errors.attributes[attribute.id] : ''}
                    />
            : (attribute.type === 'choice' && attribute.list.length) ?
                    <RadioGroup
                        alignListWithButton
                        selectedValueLabel={values.attributes[attribute.id]}
                        name={`attributes.${attribute.id}`}
                        value={values.attributes[attribute.id]}
                        placeholder={(<FormattedMessage id='product.show.product.form.field.attribute.select.placeholder' />)}
                        onChange={(event) => {
                            setFieldValue(`attributes.${attribute.id}`, event.currentTarget.value)
                        }}
                    >
                        {attribute.list.map((item, index) => (
                            <FormControlLabel
                                key={index}
                                value={item}
                                label={item}
                            />
                        ))}
                    </RadioGroup>
            : null
            }
        </>
    );
}

export default injectIntl(AttributeField);
