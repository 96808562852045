import React, {Component} from "react";
import PropTypes from "prop-types";
import {ITEM_PER_PAGE, ITEM_PER_PAGE_CHOICES} from "config/_pagination";

// @mui/material
import {MenuItem, OutlinedInput, Select, } from "@mui/material";
import {withStyles} from "@mui/styles";

// @mui/icons-material
import {UnfoldMore} from "@mui/icons-material";

// style
import ItemsPerPageSelectStyle from 'assets/jss/components/select/itemsPerPageSelectStyle';

// utils
import {encodePercentageCharacter} from "utils/encodePercentageCharacter";

class ItemsPerPageSelect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            itemsPerPage: this.props.defaultItemsPerPage ?? ITEM_PER_PAGE,
        };

        this.handleChangeItemsPerPage = this.handleChangeItemsPerPage.bind(this);
    }

    handlePageReset(params) {
        params = params.split("&");

        params.forEach((param, key) => {
            if (param.search('page') !== -1) {
                params[key] = 'page=1';
            }
        });

        return params;
    }

    handleChangeItemsPerPage(event) {
        if (this.props.onChange) {
            this.props.onChange(event);
        } else {
            let params = this.handlePageReset(decodeURIComponent(this.props.match.params.params));
            let newParams = `itemsPerPage=${event.target.value}`;

            params.map((param) => {
                if (param.search("itemsPerPage") === -1) {
                    newParams += `&${param}`;
                }

                return true;
            });

            newParams = encodePercentageCharacter(newParams)

            let url = this.props.prefixUrl ? this.props.prefixUrl + encodeURIComponent(newParams): encodeURIComponent(newParams);

            this.props.history.push(url);
        }

        this.setState({itemsPerPage: event.target.value});
    };

    render() {
        const {classes, choices} = this.props;

        return (
            <Select
                variant="standard"
                value={this.state.itemsPerPage}
                className={classes.customSelect}
                onChange={(event) => this.handleChangeItemsPerPage(event)}
                IconComponent={UnfoldMore}
                variat="filled"
                input={<OutlinedInput />}>
                {choices.map(item => {
                    return (
                        <MenuItem
                            key={item}
                            value={item}
                            classes={{
                                root: classes.customMenuItem,
                                selected: classes.customMenuItemSelected
                            }}
                        >
                            {item}
                        </MenuItem>
                    )
                })}
            </Select>
        );
    }
}

ItemsPerPageSelect.defaultProps = {
    choices: ITEM_PER_PAGE_CHOICES
};

ItemsPerPageSelect.propTypes = {
    choices: PropTypes.array.isRequired,
    prefixUrl: PropTypes.string,
    onChange: PropTypes.func,
    match: PropTypes.object,
    history: PropTypes.object,
    defaultItemsPerPage: PropTypes.oneOf(ITEM_PER_PAGE_CHOICES)
};

export default (withStyles(ItemsPerPageSelectStyle)(ItemsPerPageSelect));
