import {fetch} from "utils/dataAccess";

export default function list(params = null) {
    return new Promise((resolve, reject) => {
        fetch(`/d_a_c7_declarations?${params}&pagination=false&order[createdAt]=desc`).then(response => {
            response.json().then(response => {
                resolve(response["hydra:member"])
            })
        }).catch(error => reject(error));
    });
}
