import React from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import {useFormikContext} from "formik";

import SelectTitle from "components/radioGroup/SelectTitle";
import FormControlLabel from "components/radioGroup/FormControlLabel";
import RadioGroup from "components/radioGroup/RadioGroup";

function DeclarationCountryField({countryList, ...props}) {
    const {values, handleChange} = useFormikContext();

    return (
        <>
            <SelectTitle required={true} titleTranslationKey='compliance.dac7.form.field.declarationCountry' />
            <RadioGroup
                placeholder={<FormattedMessage id={'compliance.dac7.form.field.declarationCountry.placeholder'} />}
                alignListWithButton
                selectedValueLabel={values.declarationCountry ? props.intl.formatMessage({id :`compliance.dac7.form.country.${values.declarationCountry}`}) : ''}
                name={'declarationCountry'}
                value={values.declarationCountry}
                onChange={handleChange}
            >
                {countryList.map((countryCode, index) => (
                    <FormControlLabel
                        key={index}
                        value={countryCode}
                        label={props.intl.formatMessage({id :`compliance.dac7.form.country.${countryCode}`})}
                    />
                ))}
            </RadioGroup>
        </>
    );
}

export default injectIntl(DeclarationCountryField);
