import {fetch} from "utils/dataAccess";

export default function show(id) {
    return new Promise((resolve, reject) => {
        fetch(`/d_a_c7_declarations/${id}`).then(response => {
            response.json().then(response => {
                resolve(response)
            })
        }).catch(error => reject(error));
    });
}
