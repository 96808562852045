import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from "react-intl";
import {Formik, Form as FormikForm} from "formik";
import * as yup from "yup";
import {lazy} from "yup";
import mapValues from "lodash/mapValues";

import Grid from "@mui/material/Grid";

import FormBloc from "components/form/FormBloc";
import FormTranslator from "components/translator/formTranslator";
import Label from "components/input/new/Label";
import InputTextField from "components/input/new/InputTextField";
import ActionsContainer from "components/containers/ActionsContainer";
import CancelButtonStyled from "components/button/CancelButtonStyled";
import SaveButton from "components/button/SaveButton";
import {ListContext} from "components/List/context/ListContext";
import CustomSwitch from "components/switch/CustomSwitch";

import getTranslationInitialValues from "utils/getTranslationInitialValues";

import update from "api/attribute/update";
import {create, createBelowPosition} from "api/attribute/create";
import StyledReactSelect from "../../../../../components/select/StyledReactSelect";

Form.propTypes = {
    selectedAttribute: PropTypes.object,
    refreshList: PropTypes.func,
    createBelow: PropTypes.object,
    lastPosition: PropTypes.number.isRequired,
};

function Form({selectedAttribute, refreshList, createBelow, lastPosition, listCategoriesItem, ...props}) {
    const {loading, closeCreateDialog} = useContext(ListContext);

    const intl = props.intl;

    const validationSchema = yup.object({
        mapped: yup.bool(),
        code: yup.string().required(props.intl.formatMessage({id: 'list.form.value.required'})),
        translations: lazy(obj => yup.object(
            mapValues(obj, () => yup.object({
                name: yup.string().required(props.intl.formatMessage({id: 'list.form.value.required'}))
            }))
        ))
    });

    const initialValues = {
        'code': selectedAttribute?.code || '',
        'required': selectedAttribute?.required || false,
        'status': selectedAttribute?.status || false,
        'filtrable': selectedAttribute?.filtrable || false,
        'public': selectedAttribute?.public || false,
        'type': selectedAttribute?.type || 'string',
        'list': selectedAttribute?.list || [],
        'categories': selectedAttribute?.categories || [],
        'translations': selectedAttribute?.translations || getTranslationInitialValues()
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            validateOnChange={true}
            validateOnBlur={true}
            validateOnMount={true}
            onSubmit={(values, {setSubmitting}) => {
                let promise;

                if (selectedAttribute) { // edit
                    promise = update(selectedAttribute.id, values);
                } else { // create
                    if (createBelow?.position) {
                        values.position = createBelow.position;
                        promise = createBelowPosition(values);
                    } else {
                        values.position = lastPosition + 1;
                        promise = create(values);
                    }
                }

                promise
                    .then(() => refreshList())
                    .finally(() => setSubmitting(false))
                ;
            }}
        >
            {({
                  isSubmitting,
                  isValid,
                  values,
                  handleBlur,
                  touched,
                  errors,
                  handleChange,
                  setFieldValue,
                }) => (
                <FormikForm>
                    <Grid container rowSpacing={6}>
                        <FormBloc
                            titleId={selectedAttribute ? 'attribute.form.title.edit' : 'attribute.form.title'}
                            subtitleId='list.form.translations.required'
                        >
                            <Grid container rowSpacing={3}>
                                <Grid item xs={6}>
                                    <CustomSwitch
                                        onClick={handleChange}
                                        name="required"
                                        checked={values.required}
                                    />
                                    <FormattedMessage id="attribute.form.required" />
                                </Grid>
                                <Grid item xs={6}>
                                    <CustomSwitch
                                        onClick={handleChange}
                                        name="status"
                                        checked={values.status}
                                    />
                                    <FormattedMessage id="attribute.form.status" />
                                </Grid>
                                <Grid item xs={6}>
                                    <CustomSwitch
                                        onClick={handleChange}
                                        name="filtrable"
                                        checked={values.filtrable}
                                    />
                                    <FormattedMessage id="attribute.form.filtrable" />
                                </Grid>
                                <Grid item xs={6}>
                                    <CustomSwitch
                                        onClick={handleChange}
                                        name="public"
                                        checked={values.public}
                                    />
                                    <FormattedMessage id="attribute.form.public" />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormattedMessage id="attribute.form.type" />
                                    <StyledReactSelect
                                        onChange={option => setFieldValue("type", option.value)}
                                        name="type"
                                        placeholder={(<FormattedMessage id='attribute.form.type.placeholder' />)}
                                        value={
                                            values.type ? { value: values.type, label: <FormattedMessage id={`attribute.form.type.${values.type}`} /> } : null
                                        }
                                        options={[
                                            { value: 'string', label: <FormattedMessage id="attribute.form.type.string" /> },
                                            { value: 'float', label: <FormattedMessage id="attribute.form.type.float" /> },
                                            { value: 'url', label: <FormattedMessage id="attribute.form.type.url" /> },
                                            { value: 'choice', label: <FormattedMessage id="attribute.form.type.choice" /> },
                                            { value: 'multiple_choice', label: <FormattedMessage id="attribute.form.type.multiple_choice" /> },
                                        ]}
                                        isDisabled={selectedAttribute !== null}
                                    />
                                </Grid>
                                {(values.type === 'choice' || values.type === 'multiple_choice') && (
                                    <Grid item xs={12}>
                                        <FormattedMessage id="attribute.form.type.list.choices" />
                                        <StyledReactSelect
                                            isCreatable
                                            isMulti
                                            placeholder={<FormattedMessage id='attribute.form.type.list.choices.placeholder' />}
                                            options={[]}
                                            defaultValue={values.list.map(item => ({ value: item, label: item }))}
                                            onChange={function(selectedList){
                                                selectedList ? setFieldValue('list', selectedList.map((item) => item.label)) : []
                                            }}
                                            formatCreateLabel={(name) => `${intl.formatMessage({id: "attribute.form.type.list.choices.addOption"})} ${name}`}
                                        />
                                    </Grid>
                                )}

                                <Grid item xs={12}>
                                    <FormattedMessage id="attribute.form.type.categories" />
                                    <StyledReactSelect
                                        isMulti
                                        placeholder={<FormattedMessage id='product.show.product.form.field.attribute.multiselect.placeholder' />}
                                        options={listCategoriesItem.map(category => ({ value: category.id, label: category.displayName }))}
                                        defaultValue={values.categories.map(category => ({ value: category.id, label: listCategoriesItem.find(item => item.id === category.id)?.displayName }))}
                                        onChange={(selectedList) => setFieldValue('categories', selectedList ? selectedList.map(category => `/categories/${category.value}`) : [])}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <FormTranslator formGenerator={(index, code) => (
                                        <>
                                            <Label htmlFor={`translations.${code}.name`}>
                                                <FormattedMessage id="attribute.form.name.label" />
                                            </Label>
                                            <InputTextField
                                                name={`translations.${code}.name`}
                                                onChange={handleChange}
                                                value={values.translations[code]?.name ?? ''}
                                                placeholder={props.intl.formatMessage({id: 'attribute.form.name.placeholder'})}
                                                fullWidth
                                                autoFocus
                                                onBlur={handleBlur}
                                                error={touched.translations?.[code]?.name && Boolean(errors.translations?.[code]?.name)}
                                                helperText={touched.translations?.[code]?.name && errors.translations?.[code]?.name}
                                            />
                                        </>
                                    )} />
                                </Grid>
                            </Grid>
                        </FormBloc>
                        <FormBloc titleId='attribute.form.code.title'>
                            <Grid container rowSpacing={3}>
                                <Grid item xs={12}>
                                    <Label htmlFor={`code`}>
                                        <FormattedMessage id="attribute.form.code.label" />
                                    </Label>
                                    <InputTextField
                                        name={`code`}
                                        onChange={handleChange}
                                        value={values.code}
                                        placeholder={props.intl.formatMessage({id: 'attribute.form.code.placeholder'})}
                                        fullWidth
                                        disabled={selectedAttribute !== null}
                                        onBlur={handleBlur}
                                        error={touched.code && Boolean(errors.code)}
                                        helperText={touched.code && errors.code}
                                    />
                                </Grid>
                            </Grid>
                        </FormBloc>
                        <Grid item xs={12}>
                            <ActionsContainer>
                                <CancelButtonStyled
                                    disabled={isSubmitting}
                                    onClick={closeCreateDialog}
                                >
                                    <FormattedMessage id="list.button.cancel" />
                                </CancelButtonStyled>
                                <SaveButton
                                    type={"submit"}
                                    disabled={loading || !isValid || isSubmitting}
                                >
                                    {selectedAttribute ? (
                                        <FormattedMessage id="list.button.edit" />
                                    ) : (
                                        <FormattedMessage id="list.button.add" />
                                    )}
                                </SaveButton>
                            </ActionsContainer>
                        </Grid>
                    </Grid>
                </FormikForm>
            )}
        </Formik>
    );
}

export default injectIntl(Form);
