import React from 'react';
import PropTypes from 'prop-types';
import {List, ListItem, ListItemIcon, ListItemText, Typography} from "@mui/material";
import {Business, CalendarMonth, Home, SportsScore} from "@mui/icons-material";
import FormTitle from "../../../../../../components/form/FormTitle";
import {FormattedMessage} from "react-intl";

DeclarationResume.propTypes = {
    declaration: PropTypes.shape({
        period: PropTypes.number,
        declarationCountry: PropTypes.string,
        destinationCountry: PropTypes.string,
        operatorTaxResidence: PropTypes.string
    })
};

function DeclarationResume({declaration}) {
    const {period, declarationCountry, destinationCountry, operatorTaxResidence} = declaration;

    if (!declaration) return null;

    return (
        <div>
            <FormTitle titleId={'compliance.dac7.show.resume.title'} />
            <List>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <CalendarMonth />
                    </ListItemIcon>
                    <ListItemText
                        primary={period}
                        secondary={<FormattedMessage id={'compliance.dac7.show.resume.period'} />}
                    />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <Home />
                    </ListItemIcon>
                    <ListItemText
                        primary={<FormattedMessage id={`compliance.dac7.form.country.${declarationCountry}`} />}
                        secondary={<FormattedMessage id={'compliance.dac7.show.resume.declarationCountry'} />}
                    />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <SportsScore />
                    </ListItemIcon>
                    <ListItemText
                        primary={<FormattedMessage id={`compliance.dac7.form.country.${destinationCountry}`} />}
                        secondary={<FormattedMessage id={'compliance.dac7.show.resume.destinationCountry'} />}
                    />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <Business />
                    </ListItemIcon>
                    <ListItemText
                        primary={<FormattedMessage id={`compliance.dac7.form.field.operatorTaxResidence.${operatorTaxResidence}`} />}
                        secondary={<FormattedMessage id={'compliance.dac7.show.resume.operatorTaxResidence'} />}
                    />
                </ListItem>
            </List>
        </div>
    );
}

export default DeclarationResume;
