import normalizeTranslations from "../../actions/translations";
import {fetch, normalize} from "../../utils/dataAccess";
import {setOrganization} from "../../utils/dataAccessOrganizationHandler";

export function create(values) {
    setOrganization(values);
    values = normalizeTranslations(values);

    return new Promise((resolve, reject) => {
        fetch('/attributes', {
            method: "POST",
            body: JSON.stringify(values),
        }).then(response => response.json()).then((retrieved) => {
            resolve(normalize(retrieved));
        }).catch(error => reject(error.message));
    });
}

export function createBelowPosition(values) {
    values = normalizeTranslations(values);

    return new Promise((resolve, reject) => {
        fetch('/attributes/add_below_position', {
            method: "POST",
            body: JSON.stringify(values),
        }).then(response => response.json()).then((retrieved) => {
            resolve(normalize(retrieved));
        }).catch(error => reject(error.message));
    });
}
