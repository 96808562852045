import React from 'react';
import PropTypes from 'prop-types';

import Grid from "@mui/material/Grid";

import Card from "./Card";
import CardBody from "../card/cardBody";
import CardHeader from "../card/cardHeader";
import CardTitle from "../card/CardTitle";

import GridContainer from "../grid/gridContainer";
import GridItem from "../grid/gridItem";

import BeatLoaderRegular from "../loaders/BeatLoaderRegular";

PageContent.propTypes = {
    titleId: PropTypes.string.isRequired,
    subtitleId: PropTypes.string,
    loading: PropTypes.bool,
    fullPage: PropTypes.bool,
    rightContent: PropTypes.any,
    titleRightContent: PropTypes.any,
    paddingHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    paddingBody: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

function PageContent({fullPage, titleId, subtitleId, rightContent, titleRightContent, loading, paddingHeader, paddingBody, ...props}) {
    return (
        <Card fullPage={fullPage} noShadow={true}>
            <CardHeader
                color="info"
                style={{
                    paddingTop: '24px',
                    padding: paddingHeader && paddingHeader
                }}
            >
                <GridContainer alignItems={'center'} flexWrap={'nowrap'}>
                    <GridItem xs={10} sm>
                        <CardTitle
                            titleId={titleId}
                            subtitleId={subtitleId}
                            titleRightContent={titleRightContent}
                        />
                    </GridItem>
                    <GridItem xs={2} alignSelf={'start'}>
                        <Grid container justifyContent={"flex-end"} columnSpacing={1}>
                            <Grid item alignSelf={"center"}>
                                <BeatLoaderRegular loading={!!loading} />
                            </Grid>
                            <Grid item>
                                {rightContent && rightContent}
                            </Grid>
                        </Grid>
                    </GridItem>
                </GridContainer>
            </CardHeader>
            <CardBody style={{ overflow: "unset", padding: paddingBody && paddingBody}}>
                {props.children}
            </CardBody>
        </Card>
    );
}

export default PageContent;
