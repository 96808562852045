import React, {Component} from "react";
import {Box, Tabs} from "@mui/material";
import {withStyles} from "@mui/styles";
import PropTypes from "prop-types";
import {connect} from "react-redux";
// why we use show style from product ? 🤷‍
import showStyle from "../../assets/jss/views/product/showStyle";
import {localeData} from "../../domain/helpers/translations";
import { blueColor} from "../../assets/jss/main";
import Tab from "./Tab";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{paddingTop: '24px'}}>
                    {children}
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

class FormTranslator extends Component
{
    constructor(props) {
        super(props);
        this.state = {tabIndex: 0};
        this.locales = this.props.organization?.locales ? localeData(this.props.organization.locales) : [];
        this.dispatchChangeLocale(this.locales[0]);
    }

    // if parent component does not use redux to check if currentOrganization is set, we need this
    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.organization?.locales !== nextProps.organization?.locales) {
            this.locales = localeData(nextProps.organization?.locales);
            this.dispatchChangeLocale(this.locales[0]);
        }
    }

    handleChangeOnTabClick = (event, newValue) => {
        this.setState({tabIndex: newValue});
        this.dispatchChangeLocale(this.locales[newValue]);
    };

    dispatchChangeLocale(locale) {
        if (this.props.onLocaleChange) {
            this.props.onLocaleChange(locale);
        }
    }

    renderTabs = () => {
        return this.locales.map((locale, index) => {
            return (
                <Tab
                    label={locale.name + ' ' + locale.flag}
                    key={index} id={`simple-tab-${index}`}
                    aria-controls={`simple-tabpanel-${index}`}
                />
            )
        });
    };

    renderTabContent = () => {
        return this.locales.map((locale, index) => {
            return <TabPanel value={this.state.tabIndex} index={index} key={index}>
                {this.props.formGenerator(index, locale.code, this.props.item)}
            </TabPanel>;
        });
    };

    render = () => {
        return <div>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={this.state.tabIndex}
                    indicatorColor="primary"
                    onChange={this.handleChangeOnTabClick}
                    aria-label="translations"
                    TabIndicatorProps={{style: {background: blueColor}}}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {this.renderTabs()}
                </Tabs>
            </Box>
            {this.renderTabContent()}
        </div>;
    };
}

FormTranslator.propsTypes = {
    formGenerator: PropTypes.func,
    item: PropTypes.any,
    onLocaleChange: PropTypes.func
};

export default connect(
state => ({
        organization: state.currentOrganization.retrieved,
    })
)(withStyles(showStyle)(FormTranslator));
