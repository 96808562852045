import {fetch} from "utils/dataAccess";
import normalizeTranslations from "../../actions/translations";

export default async function update(attributeId, values)  {
    values = normalizeTranslations(values);

    const response = await fetch(`/attributes/${attributeId}`, {
        method: "PUT",
        body: JSON.stringify(values),
    });

    return await response.json();
}
