import React, {useState} from 'react';
import {Grid} from "@mui/material";

import FormBloc from "components/form/FormBloc";

import GenericOperatorInformationField from "./components/GenericOperatorInformationField";

function OperatorInformations(props) {
    const [fiels] = useState([
        'organizationName',
        'registrationName',
        'registrationNumber',
        'taxNumberCountry',
        'taxIdentificationNumber',
        'vatNumber',
        'registrationAddress'
    ]);

    return (
        <FormBloc titleId='compliance.dac7.form.operatorInformations.title'>
            <Grid container direction={'column'} rowSpacing={2}>
                {fiels.map((fieldName) => (
                    <Grid item key={fieldName}>
                        <GenericOperatorInformationField name={fieldName} />
                    </Grid>
                ))}
            </Grid>
        </FormBloc>
    );
}

export default OperatorInformations;
