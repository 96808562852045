import React from 'react';
import {FormattedMessage} from "react-intl";

import Grid from "@mui/material/Grid";

import HeaderContainer from "components/List/components/HeaderContainer";

function Header(props) {
    return (
        <HeaderContainer>
            <Grid
                item
                container
                xs={6}
                columnSpacing={2}
                sx={{ paddingLeft: '28px' }} // this is to not align with the positionPicker icon
            >
                <Grid item>
                    <FormattedMessage id={'attribute.header.name'} />
                </Grid>
            </Grid>
            <Grid
                item
                container
                xs={6}
                columnSpacing={2}
                justifyContent={"flex-end"}
                flexWrap={"nowrap"}
            >
                <Grid item sx={{ width: '70px', textAlign: 'center'}}>
                    <FormattedMessage id={'list.header.status'} />
                </Grid>
                <Grid item sx={{ width: '100px' }}>
                    <FormattedMessage id={'list.header.date'} />
                </Grid>
                <Grid item sx={{ width: '60px' }}>
                    <FormattedMessage id={'list.header.hour'} />
                </Grid>
                <Grid item sx={{ width: '80px' }}>
                    <FormattedMessage id={'list.header.actions'} />
                </Grid>
            </Grid>
        </HeaderContainer>
    );
}

export default Header;
