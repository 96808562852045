import React, {useContext} from 'react';
import FormBloc from "../../../../../../../../components/form/FormBloc";
import Grid from "@mui/material/Grid";
import {ProductShowContext} from "../../../../context/ProductShowContext";
import AttributeField from "./components/AttributeField";
import {Accordion, AccordionDetails, AccordionSummary, IconButton, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormTitle from "../../../../../../../../components/form/FormTitle";

function Attributes({isRequired, ...props}) {
    const {attributeList} = useContext(ProductShowContext);

    const grid =
            <Grid container rowSpacing={3}>
                <Grid item xs={12}>
                    <Grid container direction='column' rowSpacing={3}>
                        <Grid item container columnSpacing={2} rowSpacing={3}>
                            {Object.entries(attributeList).map(([key, values]) => (
                                ((isRequired && values.required) || (!isRequired && !values.required)) &&
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    key={key}
                                >
                                    <AttributeField attribute={values} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

    if (isRequired) {
        return (
            <FormBloc titleId='product.show.product.form.attributes.title.required'>
                {grid}
            </FormBloc>
        );
    } else {
        return (
            <Accordion
            sx={{
                width: '100%',
                border: 'none',
                boxShadow: 'none',
                '.MuiAccordion-root, .MuiAccordionSummary-root, .MuiAccordionDetails-root': {
                    paddingLeft: '0',
                    paddingRight: '0'
                },
            }}
            >
                <AccordionSummary
                    expandIcon={
                        <IconButton aria-label="settings">
                            <ExpandMoreIcon fontSize={"medium"}/>
                        </IconButton>
                    }
                    aria-controls="attributes-panel"
                    id="attributes-panel"
                >
                    <FormTitle titleId={'product.show.product.form.attributes.title'} />
                </AccordionSummary>
                <AccordionDetails>
                    {grid}
                </AccordionDetails>
            </Accordion>
        );
    }
}

export default Attributes;
